<template>
  <div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-80 z-[999] flex justify-center items-center" 
    @click="closeItself"
  >
    <!-- <div class="w-full h-full flex justify-center items-center relative"> -->
      <div v-if="ready" @click.stop class="w-[95%] max-w-[1100px] bg-zinc-800 rounded-md pb-1 pt-8 relative">
        <div class="w-full h-full max-h-[70vh]  overflow-y-auto px-3.5">
          <button @click.stop="closeItself" class="absolute top-1.5 right-2">
            <font-awesome-icon :icon="['fas', 'xmark']" class="h-5 text-zinc-300" />
          </button>
          <div class="w-full flex justify-between gap-6">
            <div class="flex flex-col">
              <h4 class="uppercase fugaz text-orange-600 -mb-1">{{ $t('log.type') }}</h4>
              <h4 class="uppercase fugaz text-white text-lg">{{actualLog.logType.name}}</h4>
            </div>
            <div class="flex flex-col items-end w-[75%]">
              <h4 class="uppercase fugaz text-orange-600 -mb-1 text-right">{{ $t('log.description') }}</h4>
              <h4 class="uppercase fugaz text-white text-lg text-right max-w-[90%]">{{actualLog.description}}</h4>
            </div>
          </div>
          <div class="px-4 bg-zinc-900 py-4 my-2 rounded-md">
            <h4 v-if="actualLog.description.toLowerCase().includes('created') || actualLog.description.toLowerCase().includes('deleted')" 
              class="fugaz uppercase text-white text-xl text-left mb-2">
              {{ $t('log.fields') }}
            </h4>
            <h4 v-else class="fugaz uppercase text-white text-xl text-left mb-2">{{ $t('log.updates') }}</h4>
            <div v-if="actualLog.logType.name == 'maintenances'" class="flex flex-wrap justify-center gap-1.5">
              <div v-for="(value, key) in filteredUpdates" class="text-white px-2 py-1 rounded-sm bg-zinc-800">
                <h4 class="fugaz uppercase text-sm">{{key}}</h4>
                <span class="" v-if="key == 'date'">{{ clearDate(value) }}</span>
                <span class="" v-else> {{ value }} </span>
              </div>
            </div>
            <div v-if="actualLog.logType.name == 'games' || actualLog.logType.name == 'platforms'">
              <div 
                v-if="actualLog.description.toLowerCase().includes('created') || actualLog.description.toLowerCase().includes('deleted')"
                class="flex flex-wrap justify-center gap-2"
              >
                <div v-for="(value, key) in filteredUpdates" class="text-white px-2 py-1 rounded-sm bg-zinc-800">
                  <h4 class="fugaz uppercase text-sm">{{key}}</h4>
                  <div class="text-lg">
                    <span v-if="key ==='release_date'">{{ clearDate(value) }}</span>
                    <span v-else-if="key.includes('description')">{{ getHtmlText(value) }}</span>
                    <span v-else>{{ value ? value : 'N/A' }}</span>
                  </div>
                </div>
              </div>
              <div v-else class="flex justify-center gap-4">
                <div class="flex flex-col w-1/2 py-2 px-1 rounded-md bg-zinc-800">
                  <div class="flex w-full">
                    <h4 class="fugaz uppercase text-lg text-white pl-2">{{ $t('log.oldFields') }}</h4>
                  </div>
                  <div class="flex flex-wrap gap-2">
                    <div v-for="(value, key) in filteredUpdates" class="text-white px-2 py-1 rounded-sm">
                      <div v-if="value.old !== undefined">
                        <div class="">
                          <span class="text-red-500 fugaz uppercase text-sm">{{key}}</span>
                          <div class="flex gap-2">
                            <span v-if="key == 'release_date'">{{ value.old ? clearDate(value.old) : 'N/A' }}</span>
                            <span v-else>{{ value.old ? value.old : 'N/A' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col w-1/2 py-2 px-1 rounded-md bg-zinc-800">
                  <div class="flex w-full">
                    <h4 class="fugaz uppercase text-lg text-white pl-2">{{ $t('log.newFields') }}</h4>
                  </div>
                  <div class="flex flex-wrap gap-2">
                    <div v-for="(value, key) in filteredUpdates" class="text-white px-2 py-1 rounded-sm">
                      <div v-if="value.new !== undefined">
                        <div class="">
                          <span class="text-green-500 fugaz uppercase text-sm">{{key}}</span> 
                          <div class="flex gap-2">
                            <span v-if="key == 'release_date'">{{ value.new ? clearDate(value.new) : 'N/A' }}</span>
                            <span v-else>{{ value.new ? value.new : 'N/A' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="actualLog.logType.name == 'bets'">
              <div class="flex flex-col md:flex-row justify-center gap-4">
                <div class="flex flex-col w-full py-2 px-1 rounded-md bg-zinc-800">
                  <div class="flex w-full">
                    <h4 class="fugaz uppercase text-lg text-white pl-2">{{ $t('log.oldBets') }}</h4>
                  </div>
                  <div class="flex flex-wrap justify-center md:justify-start pt-2 md:pt-0 gap-2 px-2">
                    <div v-for="bet in filteredUpdates.oldBets" 
                      class="text-white px-2 py-1 rounded-sm font-semibold"
                      :class="getIfBetWasRemoved(bet) ? 'bg-red-500' : 'bg-zinc-700'"
                    >
                      {{ this.getCurrencyValue(bet / 100) }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-col w-full py-2 px-1 rounded-md bg-zinc-800">
                  <div class="flex w-full">
                    <h4 class="fugaz uppercase text-lg text-white pl-2">{{ $t('log.newBets') }}</h4>
                  </div>
                  <div class="flex flex-wrap justify-center md:justify-start pt-2 md:pt-0 gap-2 px-2">
                    <div v-for="bet in filteredUpdates.newBets" 
                      class="text-white px-2 py-1 rounded-sm font-semibold"
                      :class="getIfBetIsNew(bet) ? 'bg-green-600' : 'bg-zinc-700'"
                    >
                      {{ this.getCurrencyValue(bet / 100) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="actualLog.logType.name == 'platforms'">
              <div 
                v-if="actualLog.description.toLowerCase().includes('created') || actualLog.description.toLowerCase().includes('deleted')"
                class="flex flex-wrap justify-center gap-2"
              >
                <div v-for="(value, key) in filteredUpdates" class="text-white px-2 py-1 rounded-sm bg-zinc-800">
                  <h4 class="fugaz uppercase text-sm">{{key}}</h4>
                  <div class="text-lg">
                    <span v-if="key ==='release_date'">{{ clearDate(value) }}</span>
                    <span v-else-if="key.includes('description')">{{ getHtmlText(value) }}</span>
                    <span v-else>{{ value ? value : 'N/A' }}</span>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="flex flex-wrap justify-center gap-1.5">
              <div v-for="(value, key) in filteredUpdates" class="text-white px-2 py-1 rounded-sm bg-zinc-800">
                <h4 class="capitalize font-semibold">{{key}}</h4>
                <div v-if="actualLog.description.toLowerCase().includes('created') || actualLog.description.toLowerCase().includes('deleted')">
                  <span v-if="key ==='date'">{{ clearDate(value) }}</span>
                  <span v-else-if="key.includes('description')">{{ getHtmlText(value) }}</span>
                  <span v-else>{{value}}</span>
                </div>
                <div v-else>
                  <div v-if="value.new !== undefined && value.old !== undefined">
                    <div class="pl-0.5">
                      <span class="text-green-500 font-semibold">New:</span> {{ value.new }}
                    </div>
                    <div class="pl-0.5">
                      <span class="text-red-500 font-semibold">Old:</span> {{ value.old }}
                    </div>
                  </div>
                  <div v-else>
                    <span v-if="key !== 'newBets' && key !== 'oldBets'">{{ value }}</span>
                    <div v-else>
                      <div v-if="key === 'newBets'" class="flex flex-wrap gap-1.5 items-center justify-center py-2">
                        <div v-for="bet in value" class="px-2 py-1 rounded-sm font-semibold" 
                          :class="getIfBetIsNew(bet) ? 'bg-green-500' : 'bg-zinc-600'">
                          {{ getCurrencyValue(bet / 100) }}
                        </div>
                      </div>
                      <div v-else class="flex flex-wrap gap-1.5 items-center justify-center py-2">
                        <div v-for="bet in value" class="px-2 py-1 rounded-sm font-semibold" 
                          :class="getIfBetWasRemoved(bet) ? 'bg-red-500' : 'bg-zinc-600'">
                          {{ getCurrencyValue(bet / 100) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="flex items-end border-zinc-500" 
            :class="(actualLog.logType.name === 'games' || actualLog.logType.name === 'platforms') && actualLog.relatedObject !== null ? 'justify-between' : 'justify-end'">
            <button 
              v-if="(actualLog.logType.name === 'games' || actualLog.logType.name === 'platforms') && actualLog.relatedObject !== null" 
              @click="goToRelatedItem(actualLog.relatedId, actualLog.logType.name)"
              class="text-white fugaz uppercase xl:hover:text-orange-600 transition-all duration-100 text-sm">
              {{ $t('log.open') }} {{ actualLog.logType.name === 'games' ? 'Game' : 'Platform' }}
            </button>
            <div class="flex flex-col">
              <h4 class="uppercase fugaz text-orange-600 -mb-1 text-right text-sm">{{ $t('log.createdAt') }}</h4>
              <h4 class="uppercase fugaz text-white text-base">{{clearDate(actualLog.createdAt)}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="w-full h-full flex justify-center items-center gap-3 text-zinc-600 dark:text-zinc-400"
      >
        <img src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-16 w-auto animate-pulse">
        <img
          src="../../assets/loading2.png"
          alt="loading icon"
          v-if="!serverError"
          class="h-12 animate-spin mb-2"
        />
        <div v-else>
          {{ $t('serverError') }}
          <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
          {{ $t('tryAgain') }}
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>
<script>
  import request from '@/services/axios';

  export default {
    data() {
      return {
        ready: false,
        loading: false,
        serverError: false,
        actualLog: {},
      }
    },
    props: {
      id: Number,
      clearDate: Function,
    },
    async mounted() {
      const log = (await request.get(`/logs/${this.id}`)).data
      this.actualLog = log
      this.ready = true
    },
    computed: {
      filteredUpdates() {
        const { id, updatedAt, createdAt, images, ...filtered } = this.actualLog.updates;
        const sortedFiltered = {};
        Object.keys(filtered)
          .sort()
          .forEach((key) => {
            sortedFiltered[key] = filtered[key];
          });
        return sortedFiltered;
      },
    },
    methods: {
      getIfBetIsNew(bet) {
        if (!this.actualLog.updates.oldBets.includes(bet)) return true
        else return false
      },
      getIfBetWasRemoved(bet) {
        if (!this.actualLog.updates.newBets.includes(bet)) return true
        else return false
      },
      closeItself() {
        this.emitter.emit('logBoxClosed')
      },
      getHtmlText(htmlString) {
        // Create a temporary DOM element
        var tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        // Extract the text content
        return tempElement.textContent || tempElement.innerText;
      },
    }
  }
</script>
<style scoped>

</style>
<template>
  <VueDatePicker
    v-model="date"
    :range="type === 'custom'"
    :month-picker="type === 'monthly'"
    :enable-time-picker="type === 'custom' || inline"
    :format="type !== 'custom' ? format : formatRange"
    :locale="appLanguage.long"
    :inline="inline"
    :auto-apply="inline"
    :min-date="minDate"
    time-picker-inline
    dark
    @update:model-value="update"
  >
  </VueDatePicker>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: { VueDatePicker },

  props: {
    type: {
      type: String,
      default: () => "daily",
    },
    inline: {
      type: Boolean,
      default: () => false,
    },
    minDate: {
      type: String || null,
      default: () => null,
    },
  },

  data() {
    return {
      date: new Date(),
    };
  },

  computed: {
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
  },

  watch: {
    type: {
      handler(val) {
        switch (val) {
          case "custom":
            this.date = [this.getTodayStart(), this.getTodayEnd()];
            this.updateDateOnParent();
            break;

          case "daily":
            this.date = new Date();
            this.updateDateOnParent();
            break;

          case "monthly":
            const date = new Date();
            const obj = { month: date.getMonth(), year: date.getFullYear() };
            this.date = obj;
            this.updateDateOnParent();
            break;
        }
      },
      immediate: true,
    },
  },

  methods: {
    update() {
      this.updateDateOnParent();
    },

    updateDateOnParent() {
      this.$emit("updateDate", this.date);
    },
    getTodayStart() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },

    getTodayEnd() {
      const today = new Date();
      today.setHours(23, 59, 59, 999);
      return today;
    },

    format(date) {
      console.log(date);
      if (date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        if (this.type !== "monthly") {
          // this.updateDateOnParent();
          return `${String(day).padStart(2, "0")}/${String(month).padStart(
            2,
            "0"
          )}/${year}`;
        } else {
          // this.updateDateOnParent();
          return `${String(month).padStart(2, "0")}/${year}`;
        }
      } else {
        // this.updateDateOnParent();
        return "--/--/----";
      }
    },

    formatRange(date) {
      if (date && date[0]) {
        const day1 = date[0].getDate();
        const month1 = date[0].getMonth() + 1;
        const year1 = date[0].getFullYear();
        const hours1 = date[0].getHours();
        const minutes1 = date[0].getMinutes();
        if (date[1]) {
          const day2 = date[1].getDate();
          const month2 = date[1].getMonth() + 1;
          const year2 = date[1].getFullYear();
          const hours2 = date[1].getHours();
          const minutes2 = date[1].getMinutes();

          // this.updateDateOnParent();
          return `${String(day1).padStart(2, "0")}/${String(month1).padStart(
            2,
            "0"
          )}/${year1}, ${String(hours1).padStart(2, "0")}:${String(
            minutes1
          ).padStart(2, "0")} - ${String(day2).padStart(2, "0")}/${String(
            month2
          ).padStart(2, "0")}/${year2}, ${String(hours2).padStart(
            2,
            "0"
          )}:${String(minutes2).padStart(2, "0")}`;
        }
        // this.updateDateOnParent();
        return `${String(day1).padStart(2, "0")}/${String(month1).padStart(
          2,
          "0"
        )}/${year1}, ${String(hours1).padStart(2, "0")}:${String(
          minutes1
        ).padStart(2, "0")}`;
      }
      return "--/--/----, --:--";
    },
  },
};
</script>

<style lang="postcss">
.dp__theme_dark {
  --dp-primary-color: theme(colors.orange.600);
  --dp-background-color: rgb(24, 24, 27);
}

.dp__btn {
  @apply fugaz uppercase;
}
</style>

<template>
  <div v-if="ready && !serverError" class="w-full min-h-full bg-zinc-950 relative">
    <button
      class="hidden xl:block fixed bottom-[50px] xl:bottom-0 bg-zinc-950 text-white xl:hover:text-orange-600 transition-all duration-100 fugaz uppercase px-2.5 pt-1 rounded-tr-lg z-[99] border-t border-r border-zinc-700"
      :class="sidebarFixed ? 'left-0 xl:left-72' : 'left-0 xl:left-20'"
      @click="$router.push({ name: 'Platforms' })"
    >
      <font-awesome-icon
        :icon="['fas', 'backward']"
        class="h-4 pr-1"
      />
      Platforms
    </button>
    <div class="h-full w-full rounded-md flex flex-col relative pb-2">
      <div v-if="sureDelete" @click="sureDelete = false" class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-[999]">
        <div @click.stop class="bg-zinc-800 px-5 py-3 rounded-md flex flex-col items-center gap-2">
          <h2 class="text-white text-lg font-normal">
            Are you sure you want to delete <span class="fugaz uppercase">{{platform.name}}</span> platform?
          </h2>
          <div class="flex items-center gap-3">
            <button @click="deletePlatform" 
              class="bg-zinc-700 xl:hover:bg-opacity-50 px-3 py-0.5 rounded-sm text-white fugaz uppercase">
              Confirm
            </button>
            <button @click="sureDelete = false" 
              class="bg-zinc-900 xl:hover:bg-opacity-50 px-3 py-0.5 rounded-sm text-white fugaz uppercase">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="w-full flex items-center justify-between pl-18 xl:pl-7 pr-7 pt-4 pb-4 ">
        <div class="flex items-end gap-4">
          <h1 v-if="!editingName" class="text-white text-3xl xl:text-4xl uppercase fugaz whitespace-nowrap pt-1">
            {{ platform.name}}
          </h1>
          <button v-if="!editingName" @click="editingName = !editingName" class="text-white xl:hover:text-orange-600 pb-1">
            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
          </button>
          <form @submit.prevent="updatePlatform" class="flex items-end gap-2">
            <input v-if="editingName" type="text" v-model="newName" class="text-white text-4xl uppercase fugaz bg-zinc-950 outline-none -mb-0.5 py-2 border-b border-zinc-800">
            <button v-if="editingName" type="submit" class="text-white fugaz uppercase xl:hover:text-orange-600 text-sm">
              save
            </button>
          </form>
        </div>

        <div class="">
          <button @click="openDelete" 
            v-if="platform.games.length === 0"
            class="py-0.5 px-4 xl:hover:text-red-500 fugaz uppercase text-white rounded-sm" 
            :class="{'opacity-30': this.platform.games.length > 0 }">
            Delete platform
          </button>
        </div>
      </div>
      <div class="w-full h-full flex flex-col gap-4 px-3 xl:px-7">
        <PlatformInfo :platform="platform" :updatePlatform="updatePlatform" />
        <div class="text-white flex flex-col gap-y-2 py-3 px-5 bg-zinc-900">
          <div class="flex items-center text-xl fugaz uppercase">Operators</div>
          <div v-if="platform.operators.length > 0" class="text-white flex flex-wrap gap-2.5">
            <div v-for="(operator, index) in platform.operators" :key="`operator${index}`"
              class="cursor-default py-2 px-4 rounded-lg bg-zinc-700 font-semibold"
            >
              {{ operator.name }}
            </div>
          </div>
          <div v-else>
            Empty
          </div>
        </div>
        <div class="text-white flex flex-col gap-y-2 py-3 px-5 bg-zinc-900">
          <div class="flex items-center text-xl fugaz uppercase">Games</div>
          <div v-if="platform.games.length > 0" class="text-white flex flex-wrap gap-2.5">
            <div v-for="(game, index) in platform.games" :key="`game${index}`"
              class="cursor-default py-1 px-3 rounded-lg bg-zinc-700 font-semibold"
            >
              {{ game.name }}
            </div>
          </div>
          <div v-else>
            Empty
          </div>
        </div>
        <div class="text-white flex flex-col gap-y-2 py-3 px-5 bg-zinc-900">
          <div class="flex items-center text-xl fugaz uppercase">Users</div>
          <div v-if="platform.users.length > 0" class="text-white flex flex-wrap gap-2.5">
            <div v-for="(user, index) in platform.users" :key="`user${index}`"
              class="cursor-default py-1 px-3 rounded-lg bg-zinc-700 font-semibold"
            >
              {{ user.username }}
            </div>
          </div>
          <div v-else>
            Empty
          </div>
        </div>

      </div>
    </div>
  </div>
  <div
    v-else
    class="w-full h-full flex justify-center items-center bg-zinc-200 dark:bg-zinc-900 text-zinc-600 dark:text-zinc-400"
  >
    <img src="../../assets/loading2.png" alt="loading icon"
      v-if="!serverError"
      class="h-7 animate-spin"
    />
    <div v-else>
      Error trying to connect to the server.
      <button @click="$router.go()" class="text-blue-600">Reload</button> page
      to try again.
    </div>
  </div>
</template>

<script>
import PlatformInfo from '../../components/platform/PlatformInfo.vue'
import PlatformSessions from '../../components/platform/PlatformSessions.vue'
import PlatformGames from '../../components/platform/PlatformGames.vue'
import request from '../../services/axios'
export default {
  name: 'Platform',
  components: {
    PlatformInfo,
    PlatformSessions,
    PlatformGames
  },
  data() {
    return {
      platform: {},
      actualSection: 'info',
      maintenances: [],
      ready: false,
      serverError: false,
      sureDelete: false,
      editingName: false,
      newName: '',
    }
  },
  props: {
    id: String,
  },
  computed: {
    user() {
      return this.$store.getters['user/user']
    },
    sidebarFixed() {
      return this.$store.getters["sidebar/getSidebarFixed"];
    },
  },
  async created() {
    this.emitter.on("platformGamesUpdated", this.getPlatform);
    await this.getPlatform()
  },
  methods: {
    async getPlatform() {
      this.ready = false
      try {
        const response = await request.get(`/platforms/${this.id}`)
        this.platform = response.data
        this.platform.maintenances.reverse()
        this.newName = this.platform.name
        this.ready = true
      } catch (e) {
        console.log(e);
        this.serverError = true
      }
      this.ready = true
    },
    openDelete() {
      if(this.platform.games.length > 0) {
        this.$toast.error("You can't delete a platform that has games.", {
          position: "top",
          autoClose: 3000,
        })
      } else {
        this.sureDelete = true
      }
    },
    async deletePlatform() {
      await request.delete(`/platforms/${this.id}`)
      this.$toast.success('Platform deleted')
      this.$router.push({ name: 'Platforms'})
    },
    clearDate(date) {
      const newDate = new Date(date)
      const options = {
        year: "numeric", 
        month: "long", 
        day: "numeric", 
        hour: 'numeric', 
        minute: '2-digit' 
      }
      return newDate.toLocaleString('en-GB', options)
    },
    async updatePlatform() {
      if (this.newName !== this.platform.name) {
        try {
          const {
            id,
            createdAt,
            updatedAt,
            providerId,
            games,
            users,
            maintenances,
            operators,
            name,
            ...platformFields
          } = this.platform;
          await request.put(
            `/platforms/${this.platform.id}`,
            {
              name: this.newName,
              ...platformFields,
            },
            { headers: { "Content-Type": "application/json" } }
          );
          this.platform.name = this.newName
          this.$toast.success(`Platform updated`, {
            position: "bottom",
            autoClose: 3000,
          });
          this.editingName = false
        } catch (e) {
          console.log(e)
          this.$toast.error('Something went wrong, please try again')
        }
      } else {
        this.editingName = false
        return
      }
    }
  }
}
</script>

<style>

</style>